body {
  color: white;
  font-weight: 300;
}

.App {
  text-align: center;
  background-color: #282c34;
  display: grid;
  justify-items: center;
}

.App-logo {
  margin-top: 1em;
}

.App-logo, .App-logo img {
  height: 40vmin;
  max-height: 100%;
}
@media screen and (min-width: 500px) {
  .App-logo, .App-logo img {
    height: 20vmin;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*font-size: calc(10px + 2vmin);*/
  padding: 1em;
}

h2 {
  font-size: 2em;
  font-weight: 300;
}

.App-link, a {
  color: #09d3ac;
}

.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
}

.board {
  width: 100vw;
  height: 100vw;
  background-color: grey;
  justify-self: center;
  user-select: none;
  grid-row: 2/3;
  grid-column: 1;
  position: relative;
}
@media screen and (min-width: 700px) {
  .board {
    width: 50vw;
    height: 50vw;
  }
}
@media screen and (min-width: 1550px) {
  .board {
    width: 30vw;
    height: 30vw;
  }
}

.block {
  position: absolute;
  border-style: solid;
  border-radius: 12px;
  border-color: black;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  touch-action: none;
  -ms-touch-action: none;
  -webkit-touch-callout: none;
}
.block div {
  width: min-content;
  height: min-content;
  display: flex;
  align-items: center;
  padding: 5px;
  color: black;
}

.rotate {
  transform: rotate(90deg);
}

.red {
  background-color: red;
  font-weight: 500;
}

.target {
  border-style: dashed;
  border-radius: 12px;
  box-sizing: border-box;
  border-color: black;
  position: relative;
  background-color: #ffa1a1;
  display: grid;
  align-items: center;
  justify-items: center;
  color: black;
}

.button, button {
  color: white;
  background-color: #52ab6e;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  margin: 0;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  box-shadow: none;
  border:none;
}

.counter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: 500;
}

.counter div {
  background-color: rgb(102, 143, 255);
}

.counter div:first-child {
  border-right-style: solid;
  border-color: black;
}

.winner {
  grid-row: 2/3;
  grid-column: 1;
  z-index: 5;
  background: rgba(0,0,0,0.8);
  display: grid;
  align-content: center;
}

.congrats {
  padding: 0 1em;
}